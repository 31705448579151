export const navigation = [
    {
        url: "/chatbots",
        title: "Чат-боты",
        external: false
    },
    // {
    //     url: "/statistics",
    //     title: "Статистика",
    //     external: false
    // },
    // {
    //     url: "/documentation",
    //     title: "База знаний",
    //     external: true
    // },
    // {
    //     url: "/news",
    //     title: "Новости",
    //     external: true
    // },
]
